import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//重写路由replace跳转方法（防止重复跳转路由报错）
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/login",
    component: (resolve) => require(["../views/Login/login.vue"], resolve),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/404",

    component: (resolve) => require(["../views/Login/404.vue"], resolve),
    meta: {
      title: "404",
    },
  },

  {
    path: "/homePageBottom/about",
    name: "homebottom",
    redirect: "/homePageBottom/about/company",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/homePageBottom/homePageBottom.vue"
      ),
    children: [
      {
        path: "/homePageBottom/about/company", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/about/company",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/about/company.vue"
          ),
        meta: {
          title: "公司简介",
        },
      },
      {
        path: "/homePageBottom/about/culture", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/about/culture",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/about/culture.vue"
          ),
        meta: {
          title: "企业文化",
        },
      },
      {
        path: "/homePageBottom/about/copy", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/about/copy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/about/copy.vue"
          ),
        meta: {
          title: "版权声明",
        },
      },
      {
        path: "/homePageBottom/about/enviorment", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/about/enviorment",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/about/enviorment.vue"
          ),
        meta: {
          title: "工作环境",
        },
      },
      {
        path: "/homePageBottom/about/event", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/about/event",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/about/tjhd.vue"
          ),
        meta: {
          title: "团建活动",
        },
      },
      {
        path: "/homePageBottom/about/honour", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/about/honour",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/about/honour.vue"
          ),
        meta: {
          title: "荣誉资质",
        },
      },
      {
        path: "/homePageBottom/about/joinus", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/about/joinus",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/about/joinus.vue"
          ),
        meta: {
          title: "加入我们",
        },
      },
    ],
  },
  {
    path: "/homePageBottom/contribute",
    name: "/homePageBottom/contribute",
    redirect: "/homePageBottom/contribute/step",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/homePageBottom/homePageBottom.vue"
      ),
    children: [
      {
        path: "/homePageBottom/contribute/step", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/contribute/step",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/contribute/steps.vue"
          ),
        meta: {
          title: "投发流程",
        },
      },
      {
        path: "/homePageBottom/contribute/advatage", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/contribute/advatage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/contribute/advatage.vue"
          ),
        meta: {
          title: "平台优势",
        },
      },
      {
        path: "/homePageBottom/contribute/ensure", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/contribute/ensure",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/contribute/ensure.vue"
          ),
        meta: {
          title: "平台保证",
        },
      },
      {
        path: "/homePageBottom/contribute/question", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/contribute/question",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/contribute/question.vue"
          ),
        meta: {
          title: "常见问题",
        },
      },
    ],
  },
  {
    path: "/homePageBottom/yw",
    name: "/homePageBottom/yw",
    redirect: "/homePageBottom/yw/province",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/homePageBottom/homePageBottom.vue"
      ),
    children: [
      {
        path: "/homePageBottom/yw/province", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/yw/province",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/yunwei/province.vue"
          ),
        meta: {
          title: "省级运维",
        },
      },
      {
        path: "/homePageBottom/yw/city", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/yw/city",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/yunwei/city.vue"
          ),
        meta: {
          title: "市级运维",
        },
      },
      {
        path: "/homePageBottom/yw/contact", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/yw/contact",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/yunwei/contact.vue"
          ),
        meta: {
          title: "联系我们",
        },
      },
    ],
  },
  {
    path: "/homePageBottom/enter",
    name: "/homePageBottom/enter",
    redirect: "/homePageBottom/enter/magzine",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index.vue"),
    // component: () =>
    //   import(
    //      "../views/homePageBottom/homePageBottom.vue"
    //   ),
    children: [
      {
        path: "/homePageBottom/enter/magzine", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/enter/magzine",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/enter/magzine.vue"
          ),
        meta: {
          title: "期刊入驻",
        },
      },
      {
        path: "/homePageBottom/enter/service", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/enter/service",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/enter/service.vue"
          ),
        meta: {
          title: "服务商入驻",
        },
      },
    ],
  },
  {
    path: "/homePageBottom/advicer",
    name: "/homePageBottom/advicer",
    redirect: "/homePageBottom/advicer/item",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/homePageBottom/homePageBottom.vue"
      ),
    children: [
      {
        path: "/homePageBottom/advicer/item", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/advicer/item",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/advicer/item.vue"
          ),
        meta: {
          title: "服务项目",
        },
      },
      {
        path: "/homePageBottom/advicer/steps", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/advicer/steps",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/advicer/steps.vue"
          ),
        meta: {
          title: "服务流程",
        },
      },
      {
        path: "/homePageBottom/advicer/ensure", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/advicer/ensure",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/advicer/ensure.vue"
          ),
        meta: {
          title: "担保流程",
        },
      },
    ],
  },
  {
    path: "/",
    name: "index",
    redirect: "/homePage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index.vue"),
    children: [
      {
        path: "/magzine", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "magzine",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageTop/magzine.vue"
          ),
        meta: {
          keepAlive:true,
          title: "智选期刊",
        },
      },
      {
        path: "/document", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "document",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageTop/document.vue"
          ),
        meta: {
          title: "智选文献",
        },
      },

      {
        path: "/enter", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "enter",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageTop/enter.vue"
          ),
        meta: {
          title: "期刊入驻",
        },
      },

      {
        path: "/about", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "about",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageTop/About.vue"
          ),
        meta: {
          title: "关于我们",
        },
      },
      {
        path: "/newsList", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "about",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePage/newsList.vue"
          ),
        meta: {
          title: "公司动态",
        },
      },
      {
        path: "/newsDetail", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "about",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePage/newsDetail.vue"
          ),
        meta: {
          title: "公司动态",
        },
      },

      {
        path: "/homePage",
        component: (resolve) =>
          require(["../views/homePage/homePage.vue"], resolve),
        meta: {
          title: "刊大师-首页",
        },
      },
      {
        path: "/article",
        name: "Article",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageTop/Article.vue"
          ),
        meta: {
          title: "文献分类",
        },
      },
      {
        path: "/article/detail",
        name: "Article_detail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageTop/Article_detail.vue"
          ),
        meta: {
          title: "文献详情",
        },
      },

      {
        path: "/classification", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "classification",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePage/classification.vue"
          ),
        meta: {
          title: "期刊分类",
        },
      },
      {
        path: "/searchResult", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "searchResult",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePage/searchResult.vue"
          ),
        meta: {
          title: "刊大师",
        },
      },
      {
        path: "/postingdetails", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "postingdetails",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePage/PostingDetails.vue"
          ),
        meta: {
          title: "期刊详情",
        },
      },
      {
        path: "/duplicate", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "duplicate",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePage/duplicate.vue"
          ),
        meta: {
          title: "免费查重",
        },
      },
      {
        path: "/cpufreqgetpolicy",
        component: (resolve) =>
          require(["../views/homePage/policyName.vue"], resolve),
        meta: {
          title: "职改政策",
        },
      },
      {
        path: "/policy",
        component: (resolve) =>
          require(["../views/homePage/policy.vue"], resolve),
        meta: {
          title: "职改政策",
        },
      },
      {
        path: "/code",
        component: (resolve) =>
            require(["../views/homePage/code.vue"], resolve),
        meta: {
          title: "职改政策",
        },
      },
      {
        path: "/cookiesstorepolicy",
        component: (resolve) =>
          require(["../views/homePage/cookiesstorepolicy.vue"], resolve),
        meta: {
          title: "职改政策详情",
        },
      },
      {
        path: "/intelligentand",
        component: (resolve) =>
          require(["../views/homePage/intelligentand.vue"], resolve),
        meta: {
          title: "智能投稿",
        },
      },
      {
        path: "/marketing",
        component: (resolve) =>
          require(["../views/homePage/marketing.vue"], resolve),
        meta: {
          title: "刊大师",
        },
      },

      {
        path: "/homePageBottom/enter/question", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "/homePageBottom/enter/question",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/homePageBottom/enter/question.vue"
          ),
        meta: {
          title: "常见问题", //平台入驻常见问题
        },
      },
      {
        path: "/Consultantteam",
        component: (resolve) =>
          require(["../views/homePage/Consultantteam.vue"], resolve),
        meta: {
          title: "顾问服务",
        },
      },
    ],
  },
  {
    path: "/ServicesList",
    name: "../components/FuwuHeader/Header.vue",
    redirect: "/FuwuHeader/Header.vue",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/FuwuHeader/Header.vue"
      ),
    children: [
      {
        path: "/Services",
        component: (resolve) =>
          require(["../views/FuWuShang/fuWuShang.vue"], resolve),
        meta: {
          title: "服务商",
          requireAuth: true,
        },
        children: [
          // {
          //   path: "/Services/",
          //   component: (resolve) =>
          //     require(["../views/FuWuShang/Balance/Balance333.vue"], resolve),
          //   meta: {
          //     title: "账户余额",
          //     requireAuth: true,
          //   },
          // },
          // {
          //   path: "/Services/balance",
          //   component: (resolve) =>
          //     require(["../views/FuWuShang/Balance/Balance333.vue"], resolve),
          //   meta: {
          //     title: "账户余额",
          //     requireAuth: true,
          //   },
          // },
          {
            path: "/Services/widthdraw",
            component: (resolve) =>
              require(["../views/FuWuShang/Balance/Tixian.vue"], resolve),
            meta: {
              title: "账户余额提现",
              requireAuth: true,
            },
          },
          {
            path: "/Services/",
            component: (resolve) =>
                require(["../views/FuWuShang/EmPloyees/EmPloyees.vue"], resolve),
            meta: {
              title: "员工信息",
              requireAuth: true,
            },
          },
          {
            path: "/Services/EmPloyees",
            component: (resolve) =>
              require(["../views/FuWuShang/EmPloyees/EmPloyees.vue"], resolve),
            meta: {
              title: "员工信息",
              requireAuth: true,
            },
          },
          {
            path: "/Services/EmPloyeesAdd",
            component: (resolve) =>
              require([
                "../views/FuWuShang/EmPloyees/EmPloyeesAdd.vue",
              ], resolve),
            meta: {
              title: "增加员工",
              requireAuth: true,
            },
          },
          {
            path: "/Services/EmPloyeesEdit",
            component: (resolve) =>
              require([
                "../views/FuWuShang/EmPloyees/EmPloyeesEdit.vue",
              ], resolve),
            meta: {
              title: "编辑员工信息",
              requireAuth: true,
            },
          },
          {
            path: "/Services/TodoOrder",
            component: (resolve) =>
              require(["../views/FuWuShang/Order/newOrder.vue"], resolve),
            meta: {
              title: "代办订单",
              requireAuth: true,
            },
          },
          {
            path: "/Services/EmPloyeeDetails",
            component: (resolve) =>
              require([
                "../views/FuWuShang/EmPloyeeDetails/EmPloyeeDetails.vue",
              ], resolve),
            meta: {
              title: "员工订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Services/OrderStatus",
            component: (resolve) =>
              require([
                "../views/FuWuShang/OrderStatus/OrderStatus.vue",
              ], resolve),
            meta: {
              title: "全状态订单",
              requireAuth: true,
            },
          },
          {
            path: "/Services/orderStatusDetail",
            component: (resolve) =>
              require([
                "../views/FuWuShang/OrderStatus/orderStatusDetail.vue",
              ], resolve),
            meta: {
              title: "全状态订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Services/Statistics",
            component: (resolve) =>
              require(["../views/FuWuShang/Statistics/Statistic.vue"], resolve),
            meta: {
              title: "订单统计",
              requireAuth: true,
            },
          },
          {
            path: "/Services/Security",
            component: (resolve) =>
              require(["../views/FuWuShang/Security/security.vue"], resolve),
            meta: {
              title: "安全设置",
              requireAuth: true,
            },
          },
          {
            path: "/Services/ecode",
            component: (resolve) =>
              require(["../views/FuWuShang/Ecode/Ecode.vue"], resolve),
            meta: {
              title: "专属二维码",
              requireAuth: true,
            },
          },
          {
            path: "/Services/share",
            component: (resolve) =>
              require(["../views/FuWuShang/Share/share.vue"], resolve),
            meta: {
              title: "分享链接",
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "/Staff",
        component: (resolve) =>
          require(["../views/YuanGongDuan/YuanGongDuan.vue"], resolve),
        meta: {
          title: "员工端",
          requireAuth: true,
        },
        children: [
          {
            path: "/Staff/",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/ToDoOrder/ToDoOrder.vue",
              ], resolve),
            meta: {
              title: "代办订单",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/TodoOrder",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/ToDoOrder/ToDoOrder.vue",
              ], resolve),
            meta: {
              title: "代办订单",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/order-common",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/ToDoOrder/order-common.vue",
              ], resolve),
            meta: {
              title: "服务评价",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/TodoOrder/neworderDetail",
            component: (resolve) =>
              require(["../views/YuanGongDuan/ToDoOrder/detail.vue"], resolve),
            meta: {
              title: "新订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/TodoOrder/rejectedorderDetail",
            component: (resolve) =>
              require(["../views/YuanGongDuan/ToDoOrder/detail2.vue"], resolve),
            meta: {
              title: "被驳回订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/contribution",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/Contribute/contribute.vue",
              ], resolve),
            meta: {
              title: "投稿中订单",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/contribution/detail",
            component: (resolve) =>
              require(["../views/YuanGongDuan/Contribute/detail.vue"], resolve),
            meta: {
              title: "投稿中订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/PassedOrder",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/OrderPassed/OrderPassed.vue",
              ], resolve),
            meta: {
              title: "已通过订单",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/OrderPassed/Detail",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/OrderPassed/detail.vue",
              ], resolve),
            meta: {
              title: "已通过订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/OrderStatistics",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/OrderStatistic/OrderStatistic.vue",
              ], resolve),
            meta: {
              title: "订单统计",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/OrderStatistics/detail",
            component: (resolve) =>
              require([
                "../views/YuanGongDuan/OrderStatistic/order/OrderStatisticProtocol.vue",
              ], resolve),
            meta: {
              title: "订单统计详情",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/changePassword",
            component: (resolve) =>
              require(["../views/YuanGongDuan/PassWord/security.vue"], resolve),
            meta: {
              title: "安全设置",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/ecode",
            component: (resolve) =>
              require(["../views/YuanGongDuan/HashCode/HashCode.vue"], resolve),
            meta: {
              title: "专属二维码",
              requireAuth: true,
            },
          },
          {
            path: "/Staff/share",
            component: (resolve) =>
              require(["../views/YuanGongDuan/Share/share.vue"], resolve),
            meta: {
              title: "分享链接",
              requireAuth: true,
            },
          },
        ],
      },

      {
        path: "/Periodical",
        component: (resolve) =>
          require(["../views/QiKanDuan/QiKanDuan.vue"], resolve),
        meta: {
          title: "期刊端",
          requireAuth: true,
        },
        children: [
          {
            path: "/Periodical/",
            component: (resolve) =>
              require(["../views/QiKanDuan/Setting/QKmessage.vue"], resolve),
            meta: {
              title: "期刊信息设置",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/QKmessage",
            component: (resolve) =>
              require(["../views/QiKanDuan/Setting/QKmessage.vue"], resolve),
            meta: {
              title: "期刊信息设置",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/changePassword",
            component: (resolve) =>
              require([
                "../views/QiKanDuan/Setting/changePassword.vue",
              ], resolve),
            meta: {
              title: "修改密码",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/isUrgent",
            component: (resolve) =>
                require([
                  "../views/QiKanDuan/Setting/isUrgent.vue",
                ], resolve),
            meta: {
              title: "加急开通申请",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/bindtel",
            component: (resolve) =>
              require(["../views/QiKanDuan/Setting/bindtel.vue"], resolve),
            meta: {
              title: "绑定手机号",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/firstcheck",
            component: (resolve) =>
              require(["../views/QiKanDuan/Check/firstChecked.vue"], resolve),
            meta: {
              title: "初审稿件",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/check/detail",
            component: (resolve) =>
              require(["../views/QiKanDuan/Check/Detail.vue"], resolve),
            meta: {
              title: "待审核稿件详情",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/detail/reject",
            component: (resolve) =>
              require(["../views/QiKanDuan/Check/Reject.vue"], resolve),
            meta: {
              title: "驳回稿件",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/detail/upload",
            component: (resolve) =>
              require(["../views/QiKanDuan/Check/Upload.vue"], resolve),
            meta: {
              title: "上传用稿通知",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/secondcheck",
            component: (resolve) =>
              require(["../views/QiKanDuan/Check/secondChecked.vue"], resolve),
            meta: {
              title: "复审稿件",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/passed",
            component: (resolve) =>
              require(["../views/QiKanDuan/Checked/Passed.vue"], resolve),
            meta: {
              title: "通过稿件",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/checked/detail",
            component: (resolve) =>
              require(["../views/QiKanDuan/Checked/Detail.vue"], resolve),
            meta: {
              title: "已审核稿件详情",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/rejected",
            component: (resolve) =>
              require(["../views/QiKanDuan/Checked/Rejected.vue"], resolve),
            meta: {
              title: "驳回稿件",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/balanced",
            component: (resolve) =>
              require(["../views/QiKanDuan/Balanced/Balanced.vue"], resolve),
            meta: {
              title: "已结算稿件",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/unbalanced",
            component: (resolve) =>
              require(["../views/QiKanDuan/Balanced/Unbalanced.vue"], resolve),
            meta: {
              title: "待结算稿件",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/balanced/detail",
            component: (resolve) =>
              require(["../views/QiKanDuan/Balanced/Detail.vue"], resolve),
            meta: {
              title: "稿件结算详情",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/unbalanced/apply",
            component: (resolve) =>
              require(["../views/QiKanDuan/Balanced/Apply.vue"], resolve),
            meta: {
              title: "申请结算",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/voucher",
            component: (resolve) =>
              require(["../views/QiKanDuan/Balanced/voucher.vue"], resolve),
            meta: {
              title: "查看凭证",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/history",
            component: (resolve) =>
              require(["../views/QiKanDuan/History/History.vue"], resolve),
            meta: {
              title: "历史刊发",
              requireAuth: true,
            },
          },
          {
            path: "/Periodical/history/detail",
            component: (resolve) =>
              require(["../views/QiKanDuan/History/Detail.vue"], resolve),
            meta: {
              title: "历史开发详情",
              requireAuth: true,
            },
          },
        ],
      },
      // 作者端
      {
        path: "/Pending",
        component: (resolve) =>
          require(["../views/DePenDent/dependency.vue"], resolve),
        meta: {
          title: "作者端",
          requireAuth: true,
        },
        children: [
          {
            path: "/Pending/",
            component: (resolve) =>
              require([
                "../views/DePenDent/contribute/contribute.vue",
              ], resolve),
            meta: {
              title: "投稿",
              keepAlive: true,
              requireAuth: true,
            },
          },
          {
            path: "/Pending/contribute",
            component: (resolve) =>
              require([
                "../views/DePenDent/contribute/contribute.vue",
              ], resolve),
            meta: {
              title: "投稿",
              keepAlive: true,
              requireAuth: true,
            },
          },
          {
            path: "/Pending/contributelist",
            component: (resolve) =>
              require(["../views/DePenDent/todoorder/contribute.vue"], resolve),
            meta: {
              title: "重新投稿",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/contribute/detail",
            component: (resolve) =>
              require([
                "../views/DePenDent/contribute/selectqkdetail.vue",
              ], resolve),
            meta: {
              title: "投稿详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/collection",
            component: (resolve) =>
              require([
                "../views/DePenDent/collection/collection.vue",
              ], resolve),
            meta: {
              title: "期刊收藏",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/todoorder",
            component: (resolve) =>
              require(["../views/DePenDent/todoorder/todoorder.vue"], resolve),
            meta: {
              title: "代办订单",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/todoorder/detail",
            component: (resolve) =>
              require(["../views/DePenDent/todoorder/Detail.vue"], resolve),
            meta: {
              title: "代办订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/todoorder/charge",
            component: (resolve) =>
              require(["../views/DePenDent/todoorder/charge.vue"], resolve),
            meta: {
              title: "代办订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/todoorder/drawbackdetail",
            component: (resolve) =>
              require(["../views/DePenDent/todoorder/tkDetail.vue"], resolve),
            meta: {
              title: "退款中订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/todoorder/changeDetail",
            component: (resolve) =>
              require([
                "../views/DePenDent/todoorder/changeDetail.vue",
              ], resolve),
            meta: {
              title: "更换服务商中订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/todoorder/applyservice",
            component: (resolve) =>
              require([
                "../views/DePenDent/todoorder/applyservice.vue",
              ], resolve),
            meta: {
              title: "申请服务商",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/todoorder/reuploade",
            component: (resolve) =>
              require(["../views/DePenDent/todoorder/reuploade.vue"], resolve),
            meta: {
              title: "重新上传",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/contributeorder",
            component: (resolve) =>
              require([
                "../views/DePenDent/contributeorder/contributeorder.vue",
              ], resolve),
            meta: {
              title: "投稿中订单",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/contributeorder/detail",
            component: (resolve) =>
              require([
                "../views/DePenDent/contributeorder/Detail.vue",
              ], resolve),
            meta: {
              title: "投稿中订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/contribute/selectmagzine",
            component: (resolve) =>
              require(["../views/DePenDent/contribute/selectqk.vue"], resolve),
            meta: {
              title: "选择期刊",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/history",
            component: (resolve) =>
              require(["../views/DePenDent/history/history.vue"], resolve),
            meta: {
              title: "历史订单",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/history/detail",
            component: (resolve) =>
              require(["../views/DePenDent/history/Detail.vue"], resolve),
            meta: {
              title: "历史订单详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/countsetting",
            component: (resolve) =>
              require([
                "../views/DePenDent/countsetting/countsetting.vue",
              ], resolve),
            meta: {
              title: "账户设置",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/countsetting/detail",
            component: (resolve) =>
              require(["../views/DePenDent/countsetting/Detail.vue"], resolve),
            meta: {
              title: "服务商升级进度",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/countbalance",
            component: (resolve) =>
              require([
                "../views/DePenDent/countbalance/countbalance.vue",
              ], resolve),
            meta: {
              title: "账户余额",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/costManager",
            component: (resolve) =>
                require([
                  "../views/DePenDent/costManager/costManager.vue",
                ], resolve),
            meta: {
              title: "费用申请列表",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/withdrawal",
            component: (resolve) =>
              require(["../views/DePenDent/countbalance/Tixian.vue"], resolve),
            meta: {
              title: "余额提现",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/repete",
            component: (resolve) =>
              require(["../views/DePenDent/repete/repete.vue"], resolve),
            meta: {
              title: "智能查重",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/subCheck",
            component: (resolve) =>
              require(["../views/DePenDent/repete/subCheck.vue"], resolve),
            meta: {
              title: "查重结果",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/redpacket",
            component: (resolve) =>
              require(["../views/DePenDent/redpacket/redpacket.vue"], resolve),
            meta: {
              title: "我的红包",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/coupon",
            component: (resolve) =>
              require(["../views/DePenDent/coupon/coupon.vue"], resolve),
            meta: {
              title: "我的优惠卷",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/servicepj",
            component: (resolve) =>
              require(["../views/DePenDent/servicepj/servicepj.vue"], resolve),
            meta: {
              title: "服务评价",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/servicepj/detail",
            component: (resolve) =>
              require(["../views/DePenDent/servicepj/detail.vue"], resolve),
            meta: {
              title: "服务评价详情",
              requireAuth: true,
            },
          },
          {
            path: "/Pending/sharelink",
            component: (resolve) =>
              require(["../views/DePenDent/sharelink/sharelink.vue"], resolve),
            meta: {
              title: "分享链接",
              requireAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    name: "index",
    redirect: "/homePage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index.vue"),
    children: [
      {
        path: "/hereof",
        component: (resolve) =>
          require(["../views/TiaoKuan/TiaoKuan.vue"], resolve),
        meta: {
          title: "服务条款",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
