import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 百度Echarts
// 引入echarts
var echarts = require("echarts");
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; //如果要复制没有特定按钮的文本内容,请添加此行
Vue.use(VueClipboard);
Vue.prototype.$echarts = echarts;
import clipboard from "clipboard";
//注册到vue原型上
Vue.prototype.clipboard = clipboard;
import VueAwesomeSwiper from "vue-awesome-swiper";
// import 'swiper/dist/css/swiper.min.css'
// import 'swiper/dist/js/swiper.min'
// require styles
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.js";
import "../src/js/kefu";

Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.use(ElementUI);
ElementUI.Dialog.props.lockScroll.default = false;
Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

// Vue.prototype.URL = 'http://8.140.134.42:8888'
// Vue.prototype.URL = 'http://38l214k670.qicp.vip:80'
// 头像 文件

// Vue.prototype.URL = "https://www.gckerui.com"; //姚
Vue.prototype.URL = "https://www.gckerui.com";
// 如果是开发模式
if (process.env.NODE_ENV === "development") {
  // axios.defaults.baseURL = "/api";
  axios.defaults.baseURL = "https://www.gckerui.com"; //姚
} else {
  // 放到服务器改
  // axios.defaults.baseURL='http://192.168.0.114:8888'
  axios.defaults.baseURL = "https://www.gckerui.com"; //姚
  // axios.defaults.baseURL ="http://192.168.0.130:8888"
}
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.matched.length === 0) {
    next("/404"); // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
  }
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem("id")) {
      //判断本地是否存在id
    } else {
      next({
        path: "login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    next();
  }
  next();
});

const on = Vue.prototype.$on;

Vue.prototype.$on = function(event, func) {
  let previous = 0;

  let newFunc = func;

  if (event === "click") {
    newFunc = function() {
      const now = new Date().getTime();

      if (previous + 1000 <= now) {
        func.apply(this, arguments);

        previous = now;
      }
    };
  }

  on.call(this, event, newFunc);
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
