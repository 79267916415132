window._MICHAT =
  window._MICHAT ||
  function() {
    (_MICHAT.a = _MICHAT.a || []).push(arguments);
  };
_MICHAT("accountid", 123745);
_MICHAT("domain", "mai.miyunchat.cn");
(function(m, d, q, j, s) {
  (j = d.createElement(q)), (s = d.getElementsByTagName(q)[0]);
  j.async = true;
  j.charset = "UTF-8";
  j.src =
    ("https:" == document.location.protocol ? "https://" : "http://") +
    "mai.miyunchat.cn/Web/JS/mivisit.js?_=t";
  s.parentNode.insertBefore(j, s);
})(window, document, "script");
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?4419155522418b2c8b0ec7e9abdb5beb";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
